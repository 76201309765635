<template>
  <div class="div-table-col-product-pricing">
    <p>Pricing Formula</p>
    <div>
      <el-form-item min-width="150px" style="width: 200px" label="Price Rate Type" :prop="`${priceProp}.${scopeIndex}.priceRateType`" :rules="priceType === ORDER_PRICE_TYPE.FIXED ? notReqRule : rulesReq">
        <el-select :value="productPrice.priceRateType" @input="handlePriceInfoChange($event, 'priceRateType', true)" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED">
          <el-option
            v-for="item in ORDER_PRICE_RATE_TYPE_OPTIONS"
            :key="item.value" :label="item.label" :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item min-width="100px" style="width: 150px" label="Price Metric" :prop="`${priceProp}.${scopeIndex}.priceRateTypeMetric`" :rules="priceType === ORDER_PRICE_TYPE.FIXED ? notReqRule : rulesReq">
        <el-select :value="productPrice.priceRateTypeMetric" @input="handlePriceInfoChange($event, 'priceRateTypeMetric')" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED">
          <el-option
            v-for="item in ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS"
            :key="item.value" :label="item.label" :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item min-width="300px" label="Price Methodology" :prop="`${priceProp}.${scopeIndex}.priceMethodologyId`" :rules="priceType === ORDER_PRICE_TYPE.FIXED ? notReqRule : rulesReq">
        <el-select :value="productPrice.priceMethodologyId" @input="handlePriceInfoChange($event, 'priceMethodologyId')" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED">
          <el-option
            v-for="item in priceMethodologyList.filter(d => d.bunkerwireType.toUpperCase() === scopeRow.priceRateType)"
            :key="item.id" :label="item.methodologyName" :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item min-width="100px" style="width: 100px;" :prop="`${priceProp}.${scopeIndex}.isPriceFactorFirst`">
        <el-checkbox style="padding-top: 20px" :value="productPrice.isPriceFactorFirst" @change="handlePriceInfoChange($event, 'isPriceFactorFirst')" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED || ['BUNKERWIRE', 'EX_WHARF'].includes(productPrice.priceRateType)">Factor First</el-checkbox>
      </el-form-item>
      <el-form-item min-width="150px" label="Conversion Rate">
        <el-input-number
            :controls="false" :precision="3" :min="null"
            :value="productPrice.priceConversionRate" @change="handlePriceInfoChange($event, 'priceConversionRate')" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED || ['BUNKERWIRE', 'EX_WHARF'].includes(productPrice.priceRateType)"
          />
      </el-form-item>
      <el-form-item min-width="200px" style="width: 450px" label="Pricing Period" :prop="`${priceProp}.${scopeIndex}.pricePeriod`" :rules="priceType === ORDER_PRICE_TYPE.FIXED ? notReqRule : rulesReq">
        <el-select :value="productPrice.pricePeriod" @input="handlePriceInfoChange($event, 'pricePeriod')" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED">
          <el-option
            v-for="item in ORDER_PRICE_PERIOD"
            :key="item" :label="item" :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item min-width="150px" label="Start Date" :prop="`${priceProp}.${scopeIndex}.priceStartDate`" :rules="priceType === ORDER_PRICE_TYPE.FIXED ? notReqRule : rulesReq">
        <el-date-picker
          :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED"
          :value="productPrice.priceStartDate"
          @input="handlePriceInfoChange($event, 'priceStartDate')"
          type="date"
          format="dd-MM-yyyy"
          :picker-options="dateStartOptions()"
        />
      </el-form-item>
      <el-form-item min-width="150px" label="End Date" :prop="`${priceProp}.${scopeIndex}.priceEndDate`" :rules="priceType === ORDER_PRICE_TYPE.FIXED ? notReqRule : rulesReq">
        <el-date-picker
          :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED || isPriceEndDateDisabled()"
          :value="productPrice.priceEndDate"
          @input="handlePriceInfoChange($event, 'priceEndDate')"
          type="date"
          format="dd-MM-yyyy"
          :picker-options="dateEndOptions()"
        />
      </el-form-item>
      <el-form-item class="el-form-item-price-premium" min-width="300px" label="Premium" :prop="`${priceProp}.${scopeIndex}.pricePremium`" :rules="notReqRule">
        <div class="div-price-premium">
          <el-input-number
            :controls="false" :precision="3"
            :value="productPrice.pricePremium" @change="handlePriceInfoChange($event, 'pricePremium')" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED"
          />
          <el-select class="unit-metric" :value="productPrice.priceUnitMetric" @change="handlePriceInfoChange($event, 'priceUnitMetric')" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED">
            <el-option
              v-for="item in UNIT_OPTIONS"
              :key="item.value" :label="item.label" :value="item.value"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item min-width="300px" style="width: 300px" label="Pricing Remark">
        <el-input :value="productPrice.priceRemark" @input="handlePriceInfoChange($event, 'priceRemark', true)" :disabled="isAllFieldsDisabled || priceType === ORDER_PRICE_TYPE.FIXED"/>
      </el-form-item>
      <el-form-item min-width="300px" label="Reference Price" v-if="parent === 'DialogVerifyOrder'">
        <el-input-number :controls="false" :precision="2" :value="productPrice.referencePrice" @input="handleReferencePriceChange($event)" disabled/>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { ORDER_VIEW, UNIT_OPTIONS, ORDER_PRICE_RATE_TYPE_OPTIONS, ORDER_PRICE_TYPE, ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS, ORDER_PRICE_PERIOD, ORDER_PRICE_PREMIUM_TYPE_OPTIONS } from '@/constants.js'
export default {
  name: 'TableColProductPricing',
  props: {
    scopeRow: Object,
    scopeIndex: Number,
    priceProp: String,
    priceType: String,
    priceMethodologyList: Array,
    rulesReq: Array,
    notReqRule: Array,
    holidayMap: Map,
    resetProductPriceWatcher: Boolean,
    parent: String,
    nominationDate: Date,
    isAllFieldsDisabled: Boolean
  },
  data () {
    const productPrice = {
      priceRateType: this.scopeRow.priceRateType,
      priceRateTypeMetric: this.scopeRow.priceRateTypeMetric,
      priceMethodologyId: this.scopeRow.priceMethodologyId,
      pricePeriod: this.scopeRow.pricePeriod,
      priceStartDate: this.scopeRow.priceStartDate,
      priceEndDate: this.scopeRow.priceEndDate,
      isPriceFactorFirst: this.scopeRow.isPriceFactorFirst,
      priceConversionRate: this.scopeRow.priceConversionRate,
      pricePremium: this.scopeRow.pricePremium,
      priceUnitMetric: this.scopeRow.priceUnitMetric,
      priceRemark: this.scopeRow.priceRemark,
      referencePrice: this.scopeRow.referencePrice
    }
    return {
      ORDER_VIEW,
      UNIT_OPTIONS: Object.freeze(UNIT_OPTIONS),
      ORDER_PRICE_TYPE,
      ORDER_PRICE_PERIOD,
      ORDER_PRICE_RATE_TYPE_OPTIONS: Object.freeze(ORDER_PRICE_RATE_TYPE_OPTIONS),
      ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS: Object.freeze(ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS),
      ORDER_PRICE_PREMIUM_TYPE_OPTIONS: Object.freeze(ORDER_PRICE_PREMIUM_TYPE_OPTIONS),
      productPrice,
      referencePriceBE: null
    }
  },
  watch: {
    resetProductPriceWatcher () {
      for (const key in this.productPrice) {
        this.productPrice[key] = null
      }
      this.productPrice.priceRateTypeMetric = 'MID'
      this.productPrice.priceConversionRate = 1
      this.productPrice.priceUnitMetric = 'MT'
    },
    nominationDate () {
      if (this.productPrice.pricePeriod === 'Date of Nomination (DON)') {
        this.handlePriceInfoChange('Date of Nomination (DON)', 'pricePeriod')
      }
    }
  },
  created () {
    this.calculateReferencePrice()
  },
  methods: {
    isPriceEndDateDisabled () {
      switch (this.productPrice.pricePeriod) {
        case 'Date of Delivery (DOD)':
        case 'Date of Delivery (DOD) - Start Pump':
        case 'Date of Delivery (DOD) - Stop Pump':
        case 'Date of Nomination (DON)':
          return true
        case 'Whole Monthly Average (WMA)':
          return false
        case '3 Day Average':
          return true
        case '5 Day Average':
          return true
        case '5 Day Average before Date of Delivery':
          break
        case 'Average of 01st to 15th of the month':
          return true
        case 'Average of 16th to end of the month':
          return true
        case '23rd to Month End':
          return true
        case 'Specific Date Range':
          return false
      }
    },
    dateStartOptions () {
      const disabledDate = date => {
        const currentDate = new Date()
        const pricePeriod = this.productPrice.pricePeriod
        if ((date < new Date(currentDate.getFullYear() - 2, 0, 1)) || (date > new Date(currentDate.getFullYear() + 2, 11, 31))) {
          return true
        } else if (pricePeriod === '23rd to Month End') {
          return date.getDate() !== 23
        } else if (pricePeriod === 'Average of 01st to 15th of the month') {
          return date.getDate() !== 1
        } else if (pricePeriod === 'Average of 16th to end of the month') {
          return date.getDate() !== 16
        } else if (this.holidayMap.has(date.toISOString()) || this.isWeekend(date)) {
          return true
        } else {
          return false
        }
      }
      return { disabledDate }
    },
    dateEndOptions () {
      const disabledDate = date => {
        const { priceStartDate, pricePeriod } = this.productPrice
        if (priceStartDate) {
          if ((date < new Date(priceStartDate)) || this.holidayMap.has(date.toISOString() || this.isWeekend(date))) {
            return true
          } else if (pricePeriod === 'Whole Monthly Average (WMA)') {
            const enabledDates = new Set()
            const newDate = new Date(priceStartDate)
            while (enabledDates.size < 23) {
              if (!this.holidayMap.has(newDate.toISOString()) && !this.isWeekend(newDate)) {
                enabledDates.add(newDate.toISOString())
              }
              newDate.setDate(newDate.getDate() + 1)
            }
            return !enabledDates.has(date.toISOString())
          }
        }
        return false
      }
      return { disabledDate }
    },
    isWeekend (date) {
      if (date.getDay() === 6 || date.getDay() === 0) {
        return true
      } else {
        return false
      }
    },
    handlePricingStartDateChange () {
      const addDays = (date, noOfDays) => {
        const newDate = new Date(date)
        newDate.setDate(newDate.getDate() + noOfDays)
        return newDate
      }
      const calculatePriceEndDate = (startDate, noOfDays) => {
        let remainder = noOfDays
        let newDate = new Date(startDate)
        while (remainder > 0) {
          newDate = addDays(newDate, 1)
          if (!this.holidayMap.has(newDate.toISOString()) && !this.isWeekend(newDate)) {
            remainder--
          }
          if (remainder === 0) {
            return newDate
          }
        }
      }
      if (this.productPrice.priceStartDate) {
        const priceStartDate = new Date(this.productPrice.priceStartDate)
        switch (this.productPrice.pricePeriod) {
          case 'Date of Delivery (DOD)':
          case 'Date of Delivery (DOD) - Start Pump':
          case 'Date of Delivery (DOD) - Stop Pump':
          case 'Date of Nomination (DON)':
            this.productPrice.priceEndDate = priceStartDate
            break
          case 'Whole Monthly Average (WMA)':
            this.productPrice.priceEndDate = null
            break
          case '3 Day Average':
            this.productPrice.priceEndDate = calculatePriceEndDate(priceStartDate, 3)
            break
          case '5 Day Average':
            this.productPrice.priceEndDate = calculatePriceEndDate(priceStartDate, 5)
            break
          case '5 Day Average before Date of Delivery':
            break
          case 'Average of 01st to 15th of the month':
            this.productPrice.priceEndDate = new Date(priceStartDate.getFullYear(), priceStartDate.getMonth(), 15)
            break
          case 'Average of 16th to end of the month':
            this.productPrice.priceEndDate = new Date(priceStartDate.getFullYear(), priceStartDate.getMonth() + 1, 0)
            break
          case '23rd to Month End':
            this.productPrice.priceEndDate = new Date(priceStartDate.getFullYear(), priceStartDate.getMonth() + 1, 0)
            break
          case 'Specific Date Range':
            this.productPrice.priceEndDate = null
            break
        }
      } else {
        this.productPrice.priceEndDate = null
      }
      this.$emit('handlePriceInfoChange', this.productPrice.priceEndDate, this.scopeIndex, 'priceEndDate', this.parent)
    },
    handlePriceInfoChange (e, name, isDisableCalcRefPrice) {
      this.$emit('handlePriceInfoChange', e, this.scopeIndex, name, this.parent)
      this.productPrice[name] = e
      switch (name) {
        case 'priceConversionRate':
          this.calculateFinalReferencePrice()
          break
        case 'priceRateType':
          if (e === 'BUNKERWIRE' || e === 'EX_WHARF') {
            this.productPrice.isPriceFactorFirst = false
            this.productPrice.priceConversionRate = 1
          } else {
            this.productPrice.priceConversionRate = 7.45
          }
          this.productPrice.priceMethodologyId = null
          this.handlePriceInfoChange(this.productPrice.isPriceFactorFirst, 'isPriceFactorFirst', true)
          this.handlePriceInfoChange(this.productPrice.priceConversionRate, 'priceConversionRate', true)
          this.handlePriceInfoChange(this.productPrice.priceMethodologyId, 'priceMethodologyId', true)
          this.handleReferencePriceChange(null)
          break
        case 'pricePeriod':
          if (e === 'Date of Nomination (DON)') {
            this.productPrice.priceStartDate = this.nominationDate
            this.handlePriceInfoChange(this.productPrice.priceStartDate, 'priceStartDate', true)
            this.handlePricingStartDateChange()
          } else {
            this.productPrice.priceStartDate = null
            this.productPrice.priceEndDate = null
            this.handlePriceInfoChange(this.productPrice.priceStartDate, 'priceStartDate', true)
            this.handlePriceInfoChange(this.productPrice.priceEndDate, 'priceEndDate', true)
            this.handleReferencePriceChange(null)
          }
          break
        case 'priceStartDate':
          this.handlePricingStartDateChange()
          break
      }
      if (!isDisableCalcRefPrice) {
        this.calculateReferencePrice()
      }
    },
    handleReferencePriceChange (val) {
      this.$emit('handleReferencePriceChange', val, this.scopeIndex, this.parent)
      this.productPrice.referencePrice = val
    },
    calculateReferencePrice () {
      if (this.parent === 'DialogVerifyOrder') {
        if (this.priceType === ORDER_PRICE_TYPE.FORMULA && !!this.productPrice.priceRateType && !!this.productPrice.priceRateTypeMetric &&
          !!this.productPrice.priceMethodologyId && !!this.productPrice.pricePeriod && !!this.productPrice.priceStartDate && !!this.productPrice.priceEndDate) {
          this.getMethodologyReferencePrice().then(data => {
            this.referencePriceBE = data
            this.calculateFinalReferencePrice()
          })
        } else {
          this.productPrice.referencePrice = null
          this.handleReferencePriceChange(null)
        }
      }
    },
    calculateFinalReferencePrice () {
      const premium = this.productPrice.pricePremium || 0
      let referencePrice = 0
      if (this.productPrice.isPriceFactorFirst) {
        referencePrice = (this.referencePriceBE + premium) * this.productPrice.priceConversionRate
      } else {
        referencePrice = (this.referencePriceBE * this.productPrice.priceConversionRate) + premium
      }
      this.handleReferencePriceChange(referencePrice)
    },
    async getMethodologyReferencePrice () {
      try {
        const res = await this.$request.get({
          url: `/methodologies/${this.productPrice.priceMethodologyId}/referencePrice?metricType=${this.productPrice.priceRateTypeMetric}&startDate=${new Date(this.productPrice.priceStartDate).toISOString()}&endDate=${new Date(this.productPrice.priceEndDate).toISOString()}`
        })
        if (res?.code === 1000) {
          return res.data
        }
      } catch (e) {}
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
